import { useNavigate, useParams } from "react-router-dom"
import { Form, Header, SelectField, TertiaryLink, Button } from "../../../components"
import { buildApiUrl } from "../../../utils"
import { useFetch, useReferrer } from "../../../hooks"
import { useContext, useState } from "react"
import { LocaleContext, TenantTokenContext } from "../../../contexts"
import { translations } from "../../../locales"
import { adminRoute } from "../../../routesHelpers"

const AdminEditOrderCustomer = () => {
  const { orderToken } = useParams()
  const { tenantToken } = useContext(TenantTokenContext)
  const { locale } = useContext(LocaleContext)
  const [errors, setErrors] = useState({})
  const navigate = useNavigate()
  const referrer = useReferrer()
  const { data } = useFetch(buildApiUrl(`/octopus_os/t/${tenantToken}/pos/orders/${orderToken}`, { locale }))
  const record = data.data

  const onSuccessfulSubmit = () => {
    navigate(referrer || adminRoute('orders') , { state: { adminOrders: { updatedAt: Date.now() } } })
  }

  if (!record) {
    return <p>Loading...</p>
  }

  return (
    <>
      <Header title={`${translations[locale].helpers.edit.text} ${translations[locale].models.customer.toLowerCase()}`} />

      <Form
        method='PUT'
        url={buildApiUrl(`/octopus_os/t/${tenantToken}/admin/orders/${orderToken}`)}
        onSuccessfulSubmit={onSuccessfulSubmit}
        onFailedSubmit={(errors) => setErrors(errors)}
      >
        <SelectField
            method='octopus_os_shop_order.octopus_os_shop_customer_id'
            fieldClass='flex-1 mb-4' 
            fetchOptionsUrl={buildApiUrl(`/octopus_os/t/${tenantToken}/pos/customers`)}
            serverError={errors.customer}
            defaultValue={record.customer_for_select}
            isSearchable/>

        <div className='flex justify-end gap-x-2'>
          <TertiaryLink href={-1} className='text-gray-500 font-bold'>{translations[locale].helpers.cancel.text}</TertiaryLink>
          <Button type='submit'>{translations[locale].helpers.save.text}</Button>
        </div>
      </Form>
    </>
  )
}

export default AdminEditOrderCustomer